import { CSSProperties, FC, ReactNode, useState } from 'react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import { isMobileDevice } from 'environment';
import { Grid, Hidden, IconButton } from '@mui/material';

import Icon from 'components/shared/Icon';
import { TextBody1, TitleH1 } from 'components/shared/text';
import { IAttribute, ProductAttributesDictionary, UserProfile } from 'models';
import MiniDetailsModalGallery from 'pages/catalog/layout/right-bar/MiniDetailsModalGallery';
import { getUserProfile } from 'store/account/selectors';
import { ISelectedProduct, watchProductAsync } from 'store/catalog/actions';
import { IApplicationState } from 'store/reducers';
import cartMessages from 'translations/cart/common';
import detailsMessages from 'translations/catalog/mini-details';

import { ExpandPanel } from '../../ExpandPanel';
import { ColorCircle } from '../ColorCircle';
import { OrderDetailsWrapper } from './styles';

interface IOwnProps {
  children?: ReactNode;
  item: ISelectedProduct;
  favouriteWrapper?: string;
  countImageStyle?: CSSProperties;
  maximizeImageStyle?: CSSProperties;
}

interface IStateProps {
  profile: UserProfile | undefined;
}

interface IDispatchProps {
  watchProduct: typeof watchProductAsync.request;
}

const attrsToMoreInfo = {
  [ProductAttributesDictionary.country]: true,
  [ProductAttributesDictionary.color]: true,
  [ProductAttributesDictionary.manufacturer]: true
};

type IProps = IOwnProps & IStateProps & IDispatchProps;

const isMobile = Boolean(isMobileDevice(window.navigator));

const ProductDetails: FC<IProps> = ({
  item,
  profile,
  children,
  watchProduct,
  favouriteWrapper,
  countImageStyle,
  maximizeImageStyle
}) => {
  const [modalGalleryOpen, setModalGalleryOpen] = useState(false);
  const toggleModalGallery = () => setModalGalleryOpen((state) => !state);

  const image = (img: string | undefined) =>
    img ? `${process.env.REACT_APP_API_ENDPOINT}/static/product/image/${img}` : '/images/flower-no-image.svg';

  const [checked, setChecked] = useState(false);

  const toggleMoreInfo = () => {
    setChecked((prev) => !prev);
  };

  if (item === null) {
    return null;
  }

  const productParams: IAttribute[] = [];
  const productParamsMoreInfo: IAttribute[] = [];

  Object.keys(item.attributes).forEach((attributeKey) => {
    const attribute = item.getAttribute(attributeKey);
    if (attribute.value) {
      const arrToPush = attrsToMoreInfo[attribute.code] ? productParamsMoreInfo : productParams;
      arrToPush.push(attribute);
    }
  });

  const isWatch = profile && profile.watchList.includes(item.id);

  return (
    <OrderDetailsWrapper p={3}>
      <Grid container justifyContent={'space-between'}>
        {profile && (
          <Hidden lgDown>
            <Grid item xs={6} className={clsx('details-favourite', favouriteWrapper)}>
              <IconButton
                color={'secondary'}
                onClick={() => watchProduct({ productId: item.id, watch: !isWatch })}
                size="large"
              >
                <Icon
                  type={isWatch ? 'heartFull' : 'heart'}
                  size={24}
                  className={clsx('favourite', { selected: isWatch })}
                />
              </IconButton>
            </Grid>
          </Hidden>
        )}
        <Grid onClick={toggleModalGallery} className="maximize-images" style={maximizeImageStyle}>
          <Icon type="maximize" size={24} className="maximize-images-img" />
        </Grid>
        {item?.videos?.length ? (
          <Grid className="count-videos" style={countImageStyle} onClick={toggleModalGallery}>
            <TextBody1 className="count-videos-label">
              {item.videos.length} відео
            </TextBody1>
          </Grid>
        ) : null}
        <Grid className="count-images" style={countImageStyle} onClick={toggleModalGallery}>
          <TextBody1 className="count-images-label">
            {(item?.images?.length || 0)} фото
          </TextBody1>
        </Grid>
      </Grid>

      <div className="content-container">
        <Grid item xs={5} className="image-wrapper" onClick={toggleModalGallery}>
          <div className="image-container">
            <img src={image(item.images && item.images[0])} alt={item.fullName} />
            {item.images && item.images[0] && (
              <MiniDetailsModalGallery
                images={item.images}
                videos={item.videos}
                modalOpen={modalGalleryOpen}
                toggleModalGallery={toggleModalGallery}
                title={item.fullName}
                isWatch={isWatch}
                productId={item.id}
                attributes={item.attributes}
                attributesList={item.attributesList}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={7} container className="attributes-container">
          <Grid item xs={12} className={'details-title'}>
            <TitleH1 component="h2">{item.fullName}</TitleH1>
          </Grid>
          <div className="attributes-wrapper">
            <div className="color-attr">
              <ColorCircle color={item.attributes.color?.value} className="color-circle" />
              <Hidden mdUp>
                <TextBody1 className="color-name">Колір</TextBody1>
              </Hidden>
            </div>
            {productParams.length > 0 &&
              productParams.slice(0, 3).map((param, key) => (
                <div className={'attribute-container'} key={key}>
                  <Icon type={param.alias} size={18} offset={isMobile ? 8 : 2} opacity={1} />
                  <TextBody1 className="attributes-title" component={'span'}>
                    {param.value} {param.format}
                  </TextBody1>
                </div>
              ))}
          </div>
          <div className="country-container">
            <FlagIcon
              code={item.getAttribute('country')?.value as FlagIconCode}
              size={16}
              style={{ marginRight: 10 }}
            />
            <TextBody1 className="manufacturer-label">{item.attributes.manufacturer?.value}</TextBody1>
          </div>

          <div className={clsx('detailsContainer', { detailsOpen: checked })}>
            <ExpandPanel
              onClick={toggleMoreInfo}
              defaultExpanded={checked}
              classNameSummary={clsx('summary', { summaryOpen: checked })}
              label={
                <>
                  <div className="in-package-count">
                    <Icon type="package" size={18} />
                    <span className="in-package-count-label">
                      {item.inPackageCount} {!isMobile ? 'шт.' : null}
                    </span>
                  </div>
                  {checked ? (
                    <TextBody1 className="label">{cartMessages.lessInfo.defaultMessage}</TextBody1>
                  ) : (
                    <TextBody1 className="label">{cartMessages.moreInfo.defaultMessage}</TextBody1>
                  )}
                </>
              }
              classNameDetails="expand-panel-details"
            >
              {/* TODO list of additional attributes */}
              <Grid className="more-info-item" container item xs={12}>
                <Grid item xs={6} className="more-label">
                  <TextBody1 color={'inherit'} className="more-info-label">
                    {detailsMessages.attrCategory.defaultMessage}
                  </TextBody1>
                </Grid>
                <Grid item xs={6} className="more-value">
                  <TextBody1 color={'inherit'} className="more-info-label">
                    {item.catalogCategory}
                  </TextBody1>
                </Grid>
              </Grid>
              {productParamsMoreInfo.map((attr) => (
                <Grid className="more-info-item" container item xs={12} key={attr.code}>
                  <Grid item xs={6} className="more-label">
                    <TextBody1 color={'inherit'} className="more-info-label">
                      {attr.name}
                    </TextBody1>
                  </Grid>
                  <Grid item xs={6} className="more-value">
                    <TextBody1 color={'inherit'} className="more-info-label">
                      {attr.displayValue || attr.value} {attr.format}
                    </TextBody1>
                  </Grid>
                </Grid>
              ))}
              <Grid className="more-info-item" container item xs={12}>
                <Grid item xs={6} className="more-label">
                  <TextBody1 color={'inherit'} className="more-info-label">
                    {detailsMessages.attrCode.defaultMessage}
                  </TextBody1>
                </Grid>
                <Grid item xs={6} className="more-value">
                  <TextBody1 color={'inherit'} className="more-info-label">
                    {item.code}
                  </TextBody1>
                </Grid>
              </Grid>
            </ExpandPanel>
          </div>
        </Grid>
      </div>
      {/*<Grid className="package-count">*/}
      {/*  <Icon type="package" size={24} offset={12} />*/}
      {/*  <TextBody1 className="in-package-label">{cartMessages.inPackageCount.defaultMessage}</TextBody1>*/}
      {/*  &nbsp;*/}
      {/*  <TextBody1 className="in-package-count">{item.inPackageCount}</TextBody1>*/}
      {/*</Grid>*/}
      {!!children && children}
    </OrderDetailsWrapper>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  profile: getUserProfile(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      watchProduct: watchProductAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
